import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useRouter} from "next/router";
import {useTranslation} from "next-i18next";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import Spinner from "../../components/Spinner/Spinner";
import {
    getShopStaffShopInfoPath,
    getShopStaffShopPluginSettingsPath,
    PATH_SHOP_STAFF_AUTHORIZE,
    PATH_SHOP_STAFF_PRODUCTS,
    PATH_SHOP_STAFF_SHOP_INFO,
    PATH_SHOP_STAFF_SHOP_PLUGIN_SETTINGS,
} from "../../constants/PathConstants";

function Handler({}) {
    const {t} = useTranslation(makeDefaultNs());
    const isLoading = useSelector(state => state.getIn(['shopStaffSignin', 'isLoading']));
    const shopStaff = useSelector(state => state.getIn(['shopStaffSignin', 'shopStaff']));
    const router = useRouter();

    useEffect(() => {
        if (!isLoading && shopStaff) {
            const shopId = shopStaff.get('shop_id');
            const queryParams = `?shop_staff_id=${shopStaff.get('id')}&shop_staff_auth_token=${shopStaff.get('auth_token')}&shop_staff_shop_id=${shopId}`;
            const redirect = router.query.redirect;

            if (redirect) {
                const decodeRedirect = decodeURIComponent(redirect);

                if (decodeRedirect.startsWith(PATH_SHOP_STAFF_SHOP_INFO)) {
                    window.location.replace(`${PATH_SHOP_STAFF_AUTHORIZE}${queryParams}&redirect=${decodeRedirect.replace(PATH_SHOP_STAFF_SHOP_INFO, getShopStaffShopInfoPath(shopId))}`);
                } else if (decodeRedirect.startsWith(PATH_SHOP_STAFF_SHOP_PLUGIN_SETTINGS)) {
                    window.location.replace(`${PATH_SHOP_STAFF_AUTHORIZE}${queryParams}&redirect=${decodeRedirect.replace(PATH_SHOP_STAFF_SHOP_PLUGIN_SETTINGS, getShopStaffShopPluginSettingsPath(shopId))}`);
                } else {
                    window.location.replace(`${PATH_SHOP_STAFF_AUTHORIZE}${queryParams}&redirect=${redirect || PATH_SHOP_STAFF_PRODUCTS}`);
                }
            } else {
                window.location.replace(`${PATH_SHOP_STAFF_AUTHORIZE}${queryParams}&redirect=${PATH_SHOP_STAFF_PRODUCTS}`);
            }
        }
    }, [router, isLoading, shopStaff]);

    return (
        <>
            <Spinner open={isLoading}/>
        </>
    )
}

export default Handler;
