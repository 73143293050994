import React, {useEffect} from "react";
import {wrapper} from "../../redux/stores";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import Router from "next/router";
import {PATH_EN, PATH_ROOT} from "../../constants/PathConstants";
import {usePageMount} from "../../hooks/common";
import SeoManager from "../../components/Seo/SeoManager";
import {makeRedirect, parseProps} from "../../utils/InitialPropsUtils";
import {isShopStaffInfoValid} from "../../utils/SessionUtils";
import Layout from "../../components/ShopStaffSignin/Layout";
import Handler from "../../containers/ShopStaffSignin/Handler";
import {makeShouldRedirectByLocale} from "../../behaviors/PathBehavior";

function ShopStaffSignin() {
    usePageMount();
    useEffect(() => {
        const {shopStaffInfo} = parseProps();

        if (isShopStaffInfoValid(shopStaffInfo)) {
            Router.replace(PATH_ROOT);
        }
    }, []);

    return (
        <>
            <SeoManager titleId={"signin"}/>
            <Layout/>
            <Handler/>
        </>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
    const {req, locale, resolvedUrl} = context;

    const shouldRedirect = makeShouldRedirectByLocale(locale, req.originalUrl, req.headers['accept-language']);

    if (shouldRedirect) {
        return makeRedirect(`${PATH_EN}${resolvedUrl}`);
    }

    return {
        props: {
            ...await serverSideTranslations(locale, makeDefaultNs()),
        },
    };
});

export default ShopStaffSignin;
