import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import {useTranslation} from "next-i18next";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import {signin} from "../../redux/actions/ShopStaff/ShopStaffAPIAction";
import {FontStyle} from "../../styles/StyleMixin";
import {useRouter} from "next/router";
import {PATH_SHOP_STAFF_SIGNUP} from "../../constants/PathConstants";
import {convertQueryParams2Str} from "../../utils/UrlUtils";

const ButtonLogin = styled(Button)(({theme}) => ({
    ...FontStyle(theme, 16, 24, -0.32, 500),
    width: theme.spacing(200),
    height: theme.spacing(48),
}));

const TypoCheckAccount = styled(Typography)(({theme}) => ({
    ...FontStyle(theme, 14, 17, -0.41),
    color: "#c4c4c4",
    marginTop: theme.spacing(12),
}));

const TypoSignup = styled(Button)(({theme}) => ({
    ...FontStyle(theme, 14, 17, -0.41),
    color: "#000000",
    marginLeft: theme.spacing(4),
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer",
}));

function SigninButton({}) {
    const dispatch = useDispatch();
    const router = useRouter();
    const email = useSelector(state => state.getIn(['shopStaffSignin', 'email']));
    const isEmailValid = useSelector(state => state.getIn(['shopStaffSignin', 'isEmailValid']));
    const password = useSelector(state => state.getIn(['shopStaffSignin', 'password']));
    const isPasswordValid = useSelector(state => state.getIn(['shopStaffSignin', 'isPasswordValid']));
    const {t} = useTranslation(makeDefaultNs());

    const onClick = useCallback(e => {
        e.preventDefault();

        dispatch(signin(email, password));
    }, [email, password]);

    const disabled = !isEmailValid || !isPasswordValid;

    return (
        <Box mt={30}>
            <ButtonLogin
                sx={{color: disabled ? "#000000" : "#ffffff"}}
                variant={"contained"}
                color={"primary"}
                disabled={disabled}
                onClick={onClick}
            >
                {t('signin')}
            </ButtonLogin>
            <TypoCheckAccount component={"p"}>
                {t('go_to_signup')}
                <Link passHref href={`${PATH_SHOP_STAFF_SIGNUP}${convertQueryParams2Str(router.query)}`}>
                    <TypoSignup component={"a"}>
                        {t('signup')}
                    </TypoSignup>
                </Link>
            </TypoCheckAccount>
        </Box>
    )
}

export default SigninButton;
