import React, {useCallback} from "react";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {useTranslation} from "next-i18next";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import {enterEmail, enterPassword} from "../../redux/actions/ShopStaff/SigninAction";
import {signin} from "../../redux/actions/ShopStaff/ShopStaffAPIAction";
import InputBox from "../../components/AuthCommon/InputBox";
import {KEY_CODE_ENTER} from "../../constants/CommonConstants";
import ErrorView from "./ErrorView";
import {useChangeInput} from "../../hooks/common";

const Root = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(50)} ${theme.spacing(50)} ${theme.spacing(86)} ${theme.spacing(50)}`,
        border: "1px solid #dddddd",
    },
    padding: `${theme.spacing(35)} ${theme.spacing(20)} ${theme.spacing(23)} ${theme.spacing(20)}`,
    position: "relative",
    width: "100%",
    maxWidth: theme.spacing(695),
    backgroundColor: "#ffffff",
    border: "none",
    borderRadius: theme.spacing(8),
}));

function InputContainer({}) {
    const dispatch = useDispatch();
    const email = useSelector(state => state.getIn(['shopStaffSignin', 'email']));
    const isEmailValid = useSelector(state => state.getIn(['shopStaffSignin', 'isEmailValid']));
    const password = useSelector(state => state.getIn(['shopStaffSignin', 'password']));
    const isPasswordValid = useSelector(state => state.getIn(['shopStaffSignin', 'isPasswordValid']));
    const {t} = useTranslation(makeDefaultNs());

    const onKeyPress = useCallback(e => {
        if (e.key.trim().length === 0) {
            e.preventDefault();
        }
    }, [email, password]);

    const onKeyUp = useCallback(e => {
        if (e.keyCode !== KEY_CODE_ENTER) {
            return;
        }

        if (email && isEmailValid && password && isPasswordValid) {
            dispatch(signin(email, password));
        }
    }, [email, password]);

    const onChangeEmail = useChangeInput(enterEmail);
    const onChangePassword = useChangeInput(enterPassword);

    return (
        <Root mt={35}>
            <Box mb={44}>
                <InputBox
                    label={t('email')}
                    id={"email"}
                    type={"email"}
                    variant={"outlined"}
                    fullWidth={true}
                    placeholder={t('email_placeholder')}
                    value={email}
                    onChange={onChangeEmail}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                />
            </Box>
            <Box>
                <InputBox
                    label={t('password')}
                    id={"password"}
                    type={"password"}
                    variant={"outlined"}
                    fullWidth={true}
                    placeholder={t('password_placeholder')}
                    value={password}
                    onChange={onChangePassword}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                />
            </Box>
            <ErrorView/>
        </Root>
    )
}

export default InputContainer;
