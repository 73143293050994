import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "next-i18next";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import {useSelector} from "react-redux";
import {FontStyle} from "../../styles/StyleMixin";

const Root = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: theme.spacing(32),
    },
}));

const TypoError = styled(Typography)(({theme}) => ({
    ...FontStyle(theme, 14, 20, -0.7),
}));

function ErrorView({}) {
    const error = useSelector(state => state.getIn(['shopStaffSignin', 'error']));
    const {t} = useTranslation(makeDefaultNs());

    return error && (
        <Root marginTop={20}>
            <TypoError align={"center"} color={"error"}>
                {t('fail_to_signin')}
            </TypoError>
        </Root>
    )
}

export default ErrorView;
