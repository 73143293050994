import {
    FAIL_GET_SHOP_STAFF,
    REQUEST_CHANGE_SHOP_STAFF_PASSWORD,
    REQUEST_GET_SHOP_STAFF,
    REQUEST_SIGNIN_SHOP_STAFF,
    REQUEST_SIGNUP_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_STAFF,
    SUCCEED_GET_SHOP_STAFF,
    REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    REQUEST_VERIFY_EMAIL_SHOP_STAFF,
} from "../RESTActionTypes";

export function signup(payload) {
    return {type: REQUEST_SIGNUP_SHOP_STAFF, payload};
}

export function signin(email, password) {
    return {type: REQUEST_SIGNIN_SHOP_STAFF, email, password};
}

export function verifyEmail(email, token) {
    return {type: REQUEST_VERIFY_EMAIL_SHOP_STAFF, email, token};
}

export function getShopStaff(
    shopStaffInfo,
    type = REQUEST_GET_SHOP_STAFF,
    succeedType = SUCCEED_GET_SHOP_STAFF,
    failType = FAIL_GET_SHOP_STAFF,
) {
    return {type, shopStaffInfo, succeedType, failType};
}

export function updateShopStaff(shopStaffInfo, name) {
    return {type: REQUEST_UPDATE_SHOP_STAFF, shopStaffInfo, name};
}

export function changeShopStaffPassword(shopStaffInfo, oldPassword, newPassword) {
    return {type: REQUEST_CHANGE_SHOP_STAFF_PASSWORD, shopStaffInfo, oldPassword, newPassword};
}

export function sendVerificationEmail(shopStaffInfo) {
    return {type: REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF, shopStaffInfo};
}
