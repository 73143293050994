import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "next-i18next";
import {makeDefaultNs} from "../../utils/LocaleUtils";
import {MaxWidthLayout} from "../../styles/BaseStyle";
import InputContainer from "../../containers/ShopStaffSignin/InputContainer";
import SigninButton from "../../containers/ShopStaffSignin/SigninButton";

const Root = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(30),
        paddingRight: theme.spacing(30),
    },
    ...MaxWidthLayout(theme),
}));

function Layout({}) {
    const {t} = useTranslation(makeDefaultNs());

    return (
        <Root display={"flex"} flexDirection={"column"} alignItems={"center"} pt={128}>
            <Typography variant={"h5"}>
                {t('signin_label')}
            </Typography>
            <InputContainer/>
            <SigninButton/>
        </Root>
    )
}

export default Layout;
